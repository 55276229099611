import { from, observable } from "rxjs";
import { pluck, tap, map } from "rxjs/operators";
import {
  fetchAPI,
  fetchUserAPI,
  fetchAlertAPI,
  fetchTaskAPI,
  fetchAdminAPI,
  fetchAuthAPI,
  sendEmailAPI
} from "./fetchapi";
import { navigateToUrl } from "single-spa";

import axios from "axios";

/* assuming you were hitting an actual api you'd do something like this
 * because we're not actually hitting an API now that swapi is down https://github.com/phalt/swapi/issues/147
 * we're going to fake it instead of using axios
 */

// need to consider session cookie
// ==> have session cookie, no need login, just request user info
// ==> dun have a session cookie, need to login
// after login, i should receive user info.
// then request token, sending {ou:uuid}
// then i will receive token (nav, and jwt)

const baseURL = "https://swapi.co/api/";
const axiosInstance = axios.create({
  baseURL,
  timeout: 20000
});

let userInfoList = {
  john: {
    email: "john@aim.com",
    display_name: "John",
    tenant: "",
    title: "risk manager",
    is_sso: true,
    assigned_ou: [777],
    current_ou: "",
    ou_map: {
      777: "Fortune",
      666: "Future"
    },
    public_key: "829355673283"
  },
  kay: {
    email: "kay@aim.com",
    display_name: "Kay",
    tenant: "",
    title: "global risk manager",
    is_sso: true,
    assigned_ou: [777],
    current_ou: "",
    ou_map: {
      777: "Fortune",
      666: "Future"
    },
    public_key: "829355673283"
  },
  roy: {
    email: "roy@aim.com",
    display_name: "Roy",
    tenant: "",
    title: "standard user",
    is_sso: true,
    assigned_ou: [777],
    current_ou: "",
    ou_map: {
      777: "Fortune",
      666: "Future"
    },
    public_key: "829355673283"
  },
  rob: {
    email: "rod@aim.com",
    display_name: "Rob",
    tenant: "",
    title: "standard user",
    is_sso: true,
    assigned_ou: [777],
    current_ou: "",
    ou_map: {
      777: "Fortune",
      666: "Future"
    },
    public_key: "829355673283"
  },
  ren: {
    email: "ren@aim.com",
    display_name: "Ren",
    tenant: "",
    title: "standard user",
    is_sso: true,
    assigned_ou: [777],
    current_ou: "",
    ou_map: {
      777: "Fortune",
      666: "Future"
    },
    public_key: "829355673283"
  }
};
let tokenList = {
  john: {
    nav: [
      { name: "login", url: "http://da-linux/login" },
      { name: "Risk Edit", url: "http://da-linux/risk-edit" },
      { name: "Risk Management", url: "http://da-linux/risk-management" },
      { name: "Dashboard", url: "http://da-linux/dashboard" }
    ],
    JWT:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOiIiLCJkYXRhIjp7InN1YiI6ImpvaG4ifX0.aC652TE6YPX7kA4LLt3VkrhBwpmFjb1mah9QarNx1eg"
  },
  kay: {
    nav: [
      { name: "login", url: "http://da-linux/login" },
      { name: "Risk Edit", url: "http://da-linux/risk-edit" },
      { name: "Risk Management", url: "http://da-linux/risk-management" },
      { name: "Dashboard", url: "http://da-linux/dashboard" }
    ],
    JWT: ""
  },
  roy: {
    nav: [
      { name: "login", url: "http://da-linux/login" },
      { name: "Risk Edit", url: "http://da-linux/risk-edit" },
      { name: "Risk Management", url: "http://da-linux/risk-management" },
      { name: "Dashboard", url: "http://da-linux/dashboard" }
    ],
    JWT: ""
  },
  rob: {
    nav: [
      { name: "login", url: "http://da-linux/login" },
      { name: "Risk Edit", url: "http://da-linux/risk-edit" },
      { name: "Risk Management", url: "http://da-linux/risk-management" },
      { name: "Dashboard", url: "http://da-linux/dashboard" }
    ],
    JWT: ""
  },
  ren: {
    nav: [
      { name: "login", url: "http://da-linux/login" },
      { name: "Risk Edit", url: "http://da-linux/risk-edit" },
      { name: "Risk Management", url: "http://da-linux/risk-management" },
      { name: "Dashboard", url: "http://da-linux/dashboard" }
    ],
    JWT: ""
  }
};

let sessionCookie = "";
let currentUser = {};

//  set userinfo, and user token
function login(username, password) {
  const baseURL = "http://localhost:8002/auth/token/";
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  let bodyFormData = new FormData();
  bodyFormData.set("username", username);
  bodyFormData.set("password", password);
  let axiosOptions = { data: bodyFormData };
  const options = { ...axiosOptions, ...{ method: "post" } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      currentUser = userInfoList[username];
      currentUser.token = tokenList[username];
      currentUser.token.jwt = response.data.access_token;
      currentUser.username = username;
      sessionStorage.setItem("CurrentUser", JSON.stringify(currentUser));
    })
  );
}

function handleLogout() {
  currentUser = {};
  sessionStorage.removeItem("CurrentUser");
}

function getCurrentUser() {
  return JSON.parse(sessionStorage.getItem("CurrentUser"));
}

function fetchRights(role) {
  let right = {
    create: 0,
    view: 0,
    edit: 0,
    delete: 0,
    approve: 0,
    delegate: 0
  };
  switch (role) {
    case "Risk Manager":
      right = {
        create: 1,
        view: 1,
        edit: 0,
        delete: 1,
        approve: 1,
        delegate: 0
      };
      break;
    case "Risk Owner":
      right = {
        create: 0,
        view: 1,
        edit: 1,
        delete: 0,
        approve: 0,
        delegate: 1
      };
      break;
    case "Risk Delegate":
      right = {
        create: 0,
        view: 1,
        edit: 1,
        delete: 0,
        approve: 0,
        delegate: 0
      };
      break;
    default:
      // eslint-disable-next-line no-console
      console.log("role " + role + " undefined");
  }

  return right;
}

function fetchAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(currentUserString);
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  //console.log(axiosOptionsWithPermission)
  return fetchAPI(url, axiosOptionsWithPermission);
}

function fetchAlertAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(currentUserString);
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  //console.log(axiosOptionsWithPermission)
  return fetchAlertAPI(url, axiosOptionsWithPermission);
}

function fetchTaskAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(currentUserString);
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  //console.log(axiosOptionsWithPermission)
  return fetchTaskAPI(url, axiosOptionsWithPermission);
}

function sendEmailAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(currentUserString);
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  //console.log(axiosOptionsWithPermission)
  return sendEmailAPI(url, axiosOptionsWithPermission);
}

function fetchUserAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  return fetchUserAPI(url, axiosOptions);
}

function fetchAdminAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(currentUserString);
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  //console.log(axiosOptionsWithPermission)
  return fetchAdminAPI(url, axiosOptionsWithPermission);
}
function fetchAuthAPIWithPermission(url, axiosOptions) {
  if (Object.keys(currentUser).length === 0) {
    let currentUserString = sessionStorage.getItem("CurrentUser");
    if (currentUserString != null) {
      currentUser = JSON.parse(currentUserString);
    } else {
      navigateToUrl("/login");
      // need to return something to avoid error
      // return "123";
    }
  }

  let axiosOptionsWithPermission = axiosOptions || {};
  axiosOptionsWithPermission.headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwt")
  };
  //console.log(axiosOptionsWithPermission)
  return fetchAuthAPI(url, axiosOptionsWithPermission);
}

export {
  fetchAPIWithPermission,
  fetchAlertAPIWithPermission,
  fetchTaskAPIWithPermission,
  fetchUserAPIWithPermission,
  handleLogout,
  getCurrentUser,
  fetchAdminAPIWithPermission,
  fetchAuthAPIWithPermission,
  sendEmailAPIWithPermission
};
