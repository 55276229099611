export function getPrivateKey() {
  return (
    "-----BEGIN RSA PRIVATE KEY-----\n" +
    "MIIJKQIBAAKCAgEAzyaIhVYR9DG2MawB1r4OHWjjo1OtiyCT1hQQWOpXZoSQx94F\n" +
    "mPbVcGFR7DZ/fuKbLIxj3SWVtYaN02tI4YQIP3ejo1XB25ksfJ/i7QICRve2mo7E\n" +
    "oa8lPyXhV6wT2dmiTV9FjhMZEQmUc8Sr7Jk/09y07SHHmICZv/3mh4M/3MLhPfQn\n" +
    "PKtOZOFYVyraU5nZa05fL762okbL22vkF5mj/g4AmHoHMma0ZWW6dCxyTFu42Tt2\n" +
    "foybEe7BfQvy8SNSsuE/+lxlnm8uAoGA96gh/uT3RMZFDbErlRz8NXyC9NwWG4kY\n" +
    "g2/CfvZ4+MufO2VJrIHDoVVNiTZ2Rme7btuyn5NApBnWgi/IuNC3s5/w/tgGU4CE\n" +
    "Iaplp+gRcyLeXapfvqp21XcqL/G2P6siT2Be3J28BaJ1Op9/5YiNIf2Rr0FIzfH5\n" +
    "eXjmyOYs08yaM/zwL1SJ3JepPjeuNuvtld5v0eUfJhJGBbBcV4fxBYkSsNAiZMkR\n" +
    "ac4zONKsiAPDIMG050azZpT6oZFdkAYE+SkXaB/W9CyP0uzyTBIFjxft8RolLzKO\n" +
    "Z9m/m3q9ThQojKhVr0uhneO02QRQ4mIy/3HcnBz3zU2BTluX4QR93Gfi/sTGLaGj\n" +
    "oFTkX+4JCoeF336cF+S1QpBOMcUtwi8uVsrnz8VfMt4avV4n39HuMMvUCZUCAwEA\n" +
    "AQKCAgEAuA696PNj+nydLR9vdtXiBsGm2f1EysTEdsfXCpoV1HJfjic5BAP1++eM\n" +
    "+fwmZqCETh3jsaBumB8ZL0w5Mp+1EGeGT1+oYy2DeKw73mkViucZKdVG436S8rv+\n" +
    "sH6mGMAmVOcdDzLq59eCPyb1t8EnFGEbpUOJbHh/pDa+OZQmD0pdBkZFAsQXYM1V\n" +
    "SAY8fWHK7GxzaSHkduW+xpTj0xBhxR+96b0rXd4h1aKA/C5t1Gj/Io2SKLlYI6Pn\n" +
    "6erPDvFazrQTu6mTZSXWVMWqyOJsamYZStN8kcDlP5XEhuW+GkDfp9G1pTuNLnBj\n" +
    "ZnTOY+EIiC24nEYGIa/xgXcz9DljSIsQip9AZlcMbnB6uXlUaXb3bADA5cGqhDTe\n" +
    "4M+HTyi+VH6mZV40p24nYDapuY8IExcONJTKUXWw7lyj13+in0tXDzhDf0KlvUP5\n" +
    "myvTG+SCSs6TzQkBzj8fE9nsKNsMP1DTAerRa2pqrEi55EnP0PQsTkH4O8WLQ4qP\n" +
    "mNFGMxQROQcg8CxgRMkwpk89de4Xc3a7CxlsR+T4ZFtlMKQrcCah7P9lSbp6Vhg7\n" +
    "qpeIXzVemTeq4nVTyeUhV75C/0lKTvFJxEmq7jsJA9qIpqhOndTyGYLTQh7PXAIp\n" +
    "gcVUz8MLNqgWy9vETxRFHfYA9D2BXavovAb3/a2EISOYRnJIbEECggEBAO0K8KpZ\n" +
    "xDx3CIqC9gp9oSg1cVHr012WeDsBro9z8wMK9YXrNWB1XrGa+n4xfI1EaYstsLdU\n" +
    "OJqj5EG7D7kZgPMyVs9hDis5LKupIEx2qqSq7bpLAAykTM700KnnocdD9DaL0iVH\n" +
    "zqPb3bZCm22+oXbm6/3hgOsiIujxfD4uTHh+4KJdCeUPqHymsN39CcOoAt/JdbBH\n" +
    "WdybI1hnX9m3W0mvlfyf2aNbwX1FdXdX1FZfOgxNzGVBPSy7yy6GwQEWdGcSAOAo\n" +
    "DR9/GQ4vtFhI/AiUzymuVhlXM/kU0yrcrrxPCHTjbutG+Rx+iCsYluLGs+l5tq1P\n" +
    "24eYHi6Bzep6fuUCggEBAN+3mWtQqql4qNPL4W9sXRQT/jChgCojl07Trk1vY+M0\n" +
    "2H2CU76dDXwYPDPbOT70XkyqLetHYud8cVb9PgRlwaEKKF/RvFAoXRqE1JOHkz81\n" +
    "/bB2MeAxkG1ow09E6wQDC3trPmA9eN3HG50eWkOLi+ZUdDvJeRlot2KH9+IyWvcH\n" +
    "rO4Ja97dJDy6Nw+AMLobG4YTEb7pWD+1i702L5a4BynSgbusofhrdF81Ft8x6OeW\n" +
    "McNkLkWuaZfLsEp9nVDB+AV8H16zA6JZUIxAZBDlta3SWsIp/0wz1Ms44LG/l/C+\n" +
    "U9U1igEhLptB4rkKb9pRZHZfCurRGH6xoZu3AjL7BPECggEBAKoX5pgmr+S/eZaC\n" +
    "4PCoM2M57iXdth764S1eSJdRmXh+fa3qGLQtpJSHY+X3tqtcAuxHDf6PZcq5fgoY\n" +
    "o/Crt1dLsPVAbL1hWoeXZcDYihx9Nvct6Ewp/Z+oFq9EslbdkTku3c24mDQGijLF\n" +
    "2uhGGXDLxV25Whvfs/M5jXDFIkR4rtJzNq1yrfE8X7/7We1W+zEAS/4BRR9DB3rh\n" +
    "OutsYgoe/952CeLp7Y7pr5/Cm3CbBnlbviaOSMp3uxWYxpf0N/TZ+1zeAISwXBdM\n" +
    "7flH7epudbUwD0JBy96ZLeoczxn7EFNn++jCVfE3bpiWHXd/JAyONSJqIlpLMqUm\n" +
    "qc7utW0CggEAM5U6y7K61v6aNtOQtEdRItsFRiNG0PCJ58QJMZOYfX/m1vGg65d8\n" +
    "z3srDm3iwjBIOfcXRWOdW3CnFX6ouQzZW49jBczvVbAo3393IFknS3DggLqP/3iO\n" +
    "K5v3RKTIZ5FoKVnt9PTXlJNfO5gPCfn4FPCuKEbpJEGv3wkoz7/6l3Dun6gOdkGZ\n" +
    "JGNvZKSTF6Y+b2igM9vL3Ey43rTKvj7KcAs4nJROeGAWYgl0r5vrbtKDwOCthqWT\n" +
    "dUui51oVVtSSonkfJo+yK9HaL6x9JiRBAsY45QIX+nMNApqnvHRHzVulKskAfAgx\n" +
    "ndzQMQhGCBmyR1TcyI3TzwqYD7ZfzG9ykQKCAQBVYZN15LY/aebTpzIMTFcdg7T+\n" +
    "hS1nQ1kL94YeGgcSXMt/NrWaQvyav+c5IF+HQHCGu89T/dElhkmSkKNuXDss4yzZ\n" +
    "usUSku1/7Xld0A09QDmeeozzgLgUdOC167Tl+TGjm5gVI2RuFymUPecn296DEVlV\n" +
    "2pO+WozgB+c3o8mSA8L/lr/D4XBmdfKRK2yEglrax7Nf5uuw1d+KywLroh5LnwtT\n" +
    "YGKFqT9b2iNkEyA+wenw5zyJkCTZ1IkmSNt9Nb5XHAkrHFq/hMIfyORoLraqi9f/\n" +
    "l6cS7UhqyMp5IrbPyBjao2vkkKyVlVcpKTzvqXt3WYcY/tvZW5Hh1G5Mf3ri\n" +
    "-----END RSA PRIVATE KEY-----\n"
  );
}
