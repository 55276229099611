import { navigateToUrl } from "single-spa";
import axios from "axios";
import { from } from "rxjs";
import { tap } from "rxjs/operators";
import { fetchAdminAPIWithPermission } from "./permissionAPI";
import { authUrl } from "./config";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

let currentUser = {};

let domainBaseURL = "http://test.aim.org/";

//  set userinfo, and user token
export function usernameLogin(username, password, tenant) {
  let data = { username: username, password: password, tenant: tenant };

  const baseURL = authUrl + "auth/login/username";
  axios.defaults.withCredentials = true;
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000,
    withCredentials: true
  });

  let axiosOptions = { data: data };
  const options = { ...axiosOptions, ...{ method: "post" } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      // console.log(response)
      currentUser = response.data;
      sessionStorage.setItem("CurrentUser", JSON.stringify(currentUser));
      requestToken({
        ou: response.data.current_ou,
        tenant: response.data.tenant,
        callbackFunction: () => {
          navigateToUrl("/dashboard/");
        }
      });
    })
  );
}

export function logout() {
  const userSubscription = fetchAdminAPIWithPermission("auth/logout").subscribe(
    results => {
      sessionStorage.removeItem("CurrentUser");
      sessionStorage.removeItem("MyProfile");
      sessionStorage.removeItem("jwt");
      sessionStorage.removeItem("nav");
      navigateToUrl("/login/");
    },
    err => {
      AuthCheckAndAutofix(err, () => {
        logout();
      });
    }
  );

  return () => userSubscription.unsubscribe();
}

export function requestToken(data) {
  let payloadData = { ou: data.ou, tenant: data.tenant };
  axios
    .post("http://test.aim.org/auth/token/", payloadData, {
      withCredentials: true
    })
    .then(response => {
      //console.log(response);
      sessionStorage.setItem("jwt", response.data.jwt);
      sessionStorage.setItem("nav", response.data.nav);

      getSelfProfile(data.callbackFunction);
    })
    .catch(error => {
      //console.log(error);
      // eslint-disable-next-line no-console
      console.log(error.response.data);
      if (error.response.data.detail === "Not authenticated") {
        navigateToUrl(domainBaseURL + "login");
      }
    });
}

const getSelfProfile = callbackFunction => {
  const subscription = fetchAdminAPIWithPermission(
    "auth/profile/view"
  ).subscribe(
    results => {
      sessionStorage.setItem("MyProfile", JSON.stringify(results.data));
      // handleUserListRequest()
      if (callbackFunction) {
        callbackFunction();
      }
    },
    err => {
        console.log(err); // eslint-disable-line
    }
  );

  return () => subscription.unsubscribe();
};
