import { from } from "rxjs";
import { pluck, tap, map } from "rxjs/operators";
import { fakeAPIFetch } from "./fake-backend/fake-backend.js";
import axios from "axios";
import {
  authUrl,
  riskUrl,
  userUrl,
  alertUrl,
  taskUrl,
  emailUrl
} from "./config";

/* assuming you were hitting an actual api you'd do something like this
 * because we're not actually hitting an API now that swapi is down https://github.com/phalt/swapi/issues/147
 * we're going to fake it instead of using axios
 */
// const baseURL = "https://swapi.co/api/";
//
// const axiosInstance = axios.create({
//   baseURL,
//   timeout: 20000
// });

const tenMin = 1000 /* ms */ * 60 /* sec */ * 10;

export function fetchAPI(url, axiosOptions) {
  const baseURL = riskUrl;
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "post" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}

export function fetchAlertAPI(url, axiosOptions) {
  const baseURL = alertUrl;
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "post" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}

export function fetchTaskAPI(url, axiosOptions) {
  const baseURL = taskUrl;
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "post" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}
export function fetchUserAPI(url, axiosOptions) {
  const baseURL = userUrl;
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "get" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}

export function fetchAdminAPI(url, axiosOptions) {
  const baseURL = authUrl + "api/";
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "post" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}

export function fetchAuthAPI(url, axiosOptions) {
  const baseURL = authUrl + "auth/";
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "post" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}
export function sendEmailAPI(url, axiosOptions) {
  const baseURL = emailUrl;
  const axiosInstance = axios.create({
    baseURL,
    timeout: 20000
  });
  const options = { ...axiosOptions, ...{ method: "post" }, ...{ url } };

  return from(axiosInstance.request(options)).pipe(
    tap(response => {
      cache[options.url] = {
        lastPulled: Date.now(),
        value: response
      };
      if (response.results && Array.isArray(response.results)) {
        response.results.forEach(item => {
          if (item.url) {
            cache[item.url] = {
              lastPulled: Date.now(),
              value: item
            };
          }
        });
      }
    })
  );
}

const cache = {};
